var fscb = window.fscb || {};

fscb.app = (function() {

	var self = {};

	fscb.pageType = '';

	self.init = function(){
		$('html').removeClass('no-js').addClass('js-ready');
		if (fscb.utils.isTouch()){
			$('body').addClass('device-touch');
		}

		/* dichiarazioni variabili */
		fscb.pageType = $('body').data('page');
		fscb.breakpoints = {
			xs:500,
			sm:768,
			md:1024,
			lg:1280
		};
		fscb.commons.init();
		switchPage();
	};

	function switchPage(){
		switch(fscb.pageType) {
			case 'catalogo':
				fscb.commons.expandSearch();
			break;
			default:
		}
	}

	return self;

})();